export const STATUS_CONNECTION_ERROR = Symbol('STATUS_CONNECTION_ERROR')
export const STATUS_CONNECTION_HEALTHY = Symbol('STATUS_CONNECTION_HEALTHY')
export const STATUS_CONNECTION_WARNING = Symbol('STATUS_CONNECTION_WARNING')

export const STATUS_WALLET_CONNECTION_DROPPED = Symbol(
  'STATUS_WALLET_CONNECTION_DROPPED'
)
export const STATUS_CLIENT_CONNECTION_DROPPED = Symbol(
  'STATUS_CLIENT_CONNECTION_DROPPED'
)
export const STATUS_NETWORK_SYNC_ISSUES = Symbol('STATUS_NETWORK_SYNC_ISSUES')
export const STATUS_MAJOR_NETWORK_SLOWDOWN = Symbol(
  'STATUS_MAJOR_NETWORK_SLOWDOWN'
)
export const STATUS_TOO_LITTLE_ETH = Symbol('STATUS_TOO_LITTLE_ETH')
export const STATUS_CONNECTION_OK = Symbol('STATUS_CONNECTION_OK')
