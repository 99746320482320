import React from 'react'
import useIconSize from '../icon-size'
import IconPropTypes from '../IconPropTypes'

function IconHide({ size, ...props }) {
  const sizeValue = useIconSize(size)
  return (
    <svg
      width={sizeValue}
      height={sizeValue}
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M17.046 16.027a.727.727 0 00-1.02-.138 6.8 6.8 0 01-4.032 1.4c-1.962-.001-3.828-.927-5.548-2.75a14.135 14.135 0 01-1.888-2.537A13.052 13.052 0 017.974 8.11a.727.727 0 00-.883-1.155 14.506 14.506 0 00-4.005 4.701.727.727 0 00-.01.67c.033.064.817 1.616 2.283 3.18.87.929 1.805 1.671 2.776 2.207 1.24.684 2.54 1.031 3.865 1.031h.012a8.245 8.245 0 004.896-1.698c.32-.244.381-.7.138-1.02zm3.878-4.352c-.033-.065-.817-1.617-2.283-3.18-.87-.93-1.805-1.672-2.776-2.208-1.24-.684-2.54-1.03-3.863-1.03a7.59 7.59 0 00-1.747.199.727.727 0 10.331 1.416A6.198 6.198 0 0112 6.71c1.964 0 3.833.926 5.555 2.752a14.126 14.126 0 011.888 2.537c-.391.682-.845 1.33-1.35 1.932a.727.727 0 101.112.936 14.667 14.667 0 001.71-2.524.727.727 0 00.009-.668z"
      />
      <path
        fill="currentColor"
        d="M14.09 13.063a.727.727 0 00-1.027.036 1.52 1.52 0 01-1.065.486 1.53 1.53 0 01-1.096-2.647.727.727 0 10-.992-1.064 2.987 2.987 0 000 4.365 2.963 2.963 0 002.139.799 2.964 2.964 0 002.078-.948.727.727 0 00-.037-1.027z"
      />
      <path
        fill="currentColor"
        d="M20.788 19.76L4.24 3.212A.727.727 0 103.212 4.24l16.546 16.547a.725.725 0 001.029 0 .727.727 0 000-1.029z"
      />
    </svg>
  )
}

IconHide.propTypes = IconPropTypes
export default IconHide
